<template>
  <div class="bb">
  <div class="wrapper">
    <div class="marquee1"
        style="position:fixed; bottom:calc(50vh - 300px);left:calc(50vw - 220px);margin: 200px auto; background-color: #fff;border: 2px solid whitesmoke;
        width: 350px; height: 300px; padding: 20px 40px; border-radius: 10px;z-index:888;opacity: 1.0;box-shadow: 15px 12px 20px black;">
      <div style="padding-bottom: 20px; text-align: center; font-size: 24px"><b>Awen学习交流网</b></div>
      <el-form :model="user" :rules="rules" ref="userForm">
        <el-form-item prop="username">
          <el-input size="medium" prefix-icon="el-icon-user" v-model="user.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input size="medium" prefix-icon="el-icon-lock" show-password
                    v-model="user.password"></el-input>
        </el-form-item>

      <el-form-item>
<!--        <valid-icon @update:value="getIcon" />-->
        <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between">
          <div>请点击下面 <span style="color: orange;font-weight: 800; font-size: 15px">{{iconList[validateIcon].name}}</span> 图标</div>
          <el-button type="primary" size="mini" icon="el-icon-refresh" @click="refresh" :disabled="validRult">刷新</el-button>
        </div>

        <div style="width:100%;display: flex;flex-direction: row;justify-content: space-around;" :class="className">
          <template v-for="(item, index) in iconList">
            <div style="display: flex;flex-direction: column;height: 60px;overflow: hidden" :disabled="!validRult">
              <div class="iconBox" :style="'display: flex;flex-direction: column;background-color:'+item.color+';margin: 10px;align-items: center;'">
                <i :class="item.icon" :style="'color:'+item.color+';filter: invert(100%);'" @click="clickIcon" :id="index" ></i>
              </div>
              <div v-if="selectedNum == index"style="position: relative;top:-50px;left: 15px;">
                <img v-if="validateIcon == index" src="../assets/imgs/shuruzhengque.png"/>
                <img v-else src="../assets/imgs/cuowu.png"/>
              </div>
            </div>

          </template>
        </div>
      </el-form-item>

        <el-form-item style="margin: 10px 0; text-align: right;z-index: 8888">
<!--          <el-button type="warning" size="small" autocomplete="off" @click="$router.push('/register')">注册</el-button>-->
          <el-button type="warning" size="small" autocomplete="off" @click="register">注册</el-button>
          <el-button type="primary" size="small" autocomplete="off" @click="login"  :disabled="!validRult">登录</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="position:fixed; bottom: 20px; display: flex;flex-direction: column;width:100%;align-items: center;justify-content: space-around;z-index: 9999;opacity: 1.0;">
      <div>© 2024 yz_awen ®</div>
      <el-link type="primary" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">桂ICP备2023000767号-1</el-link>
      <div style="display: flex;flex-direction: row">
        <el-image :src="require('@/assets/安备.png')" style="width: 20px; height: 20px"></el-image>
        <el-link type="primary" href="https://beian.mps.gov.cn/#/query/webSearch?code=45128102451368" target="_blank">桂公网安备45128102451368号</el-link>
        <div></div>
      </div>
    </div>

  </div>
  <div class="wrapper2"></div>
  </div>
</template>

<script>
// import ValidIcon from "@/conponents/ValidIcon";


import {setRoutes} from "@/router";

export default {
  name: "Login",
  components: {
    // ValidIcon
  },
  data() {
    return {
      className:'',
      user: {},
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'}
        ],
      },
      iconList: [],   // 随机从下面qweather_icons获取六个图标
      validateIcon: null,   // 正确的序号
      selectedNum: null,    // 被选上的序号
      validRult: false,     // 验证结果
      clicked: false,       // 是否点击过
      // 和风天气图标
      qweather_icons: [
        {name: '晴天', icon: 'qi-sunny'},
        {name: '多云', icon: 'qi-cloudy'},
        {name: '少云', icon: 'qi-few-clouds'},
        {name: '局部多云', icon: 'qi-partly-cloudy'},
        {name: '阴天', icon: 'qi-overcast'},
        {name: '晴夜', icon: 'qi-clear-night'},
        {name: '阴夜', icon: 'qi-cloudy-night'},
        {name: '夜间少云', icon: 'qi-few-clouds-night'},
        {name: '夜间多云', icon: 'qi-partly-cloudy-night'},
        {name: '阵雨', icon: 'qi-shower-rain'},
        {name: '大阵雨', icon: 'qi-heavy-shower-rain'},
        {name: '雷阵雨', icon: 'qi-thundershower'},
        {name: '强雷暴', icon: 'qi-heavy-thunderstorm'},
        {name: '雷阵雨夹冰雹', icon: 'qi-thundershower-with-hail'},
        {name: '小雨', icon: 'qi-light-rain'},
        {name: '中雨', icon: 'qi-moderate-rain'},
        {name: '大雨', icon: 'qi-heavy-rain'},
        {name: '特大雨', icon: 'qi-extreme-rain'},
        {name: '毛毛雨', icon: 'qi-drizzle-rain'},
        {name: '风暴', icon: 'qi-storm'},
        {name: '暴雨', icon: 'qi-heavy-storm'},
        {name: '强风暴', icon: 'qi-severe-storm'},
        {name: '冻雨', icon: 'qi-freezing-rain'},
        {name: '小到中雨', icon: 'qi-light-to-moderate-rain'},
        {name: '中到大雨', icon: 'qi-moderate-to-heavy-rain'},
        {name: '大雨到暴雨', icon: 'qi-heavy-rain-to-storm'},
        {name: '暴雨到大暴雨', icon: 'qi-storm-to-heavy-storm'},
        {name: '大暴雨到特大暴雨', icon: 'qi-heavy-to-severe-storm'},
        {name: '夜间阵雨', icon: 'qi-shower-rain-night'},
        {name: '夜间大阵雨', icon: 'qi-heavy-shower-rain-night'},
        {name: '雨', icon: 'qi-rain'},
        {name: '小雪', icon: 'qi-light-snow'},
        {name: '中雪', icon: 'qi-moderate-snow'},
        {name: '大雪', icon: 'qi-heavy-snow'},
        {name: '暴雪', icon: 'qi-snowstorm'},
        {name: '霰', icon: 'qi-sleet'},
        {name: '雨雪', icon: 'qi-rain-and-snow'},
        {name: '阵雪', icon: 'qi-shower-snow'},
        {name: '飘雪', icon: 'qi-snow-flurry'},
        {name: '小雪至中雪', icon: 'qi-light-to-moderate-snow'},
        {name: '中雪至大雪', icon: 'qi-moderate-to-heavy-snow'},
        {name: '大雪至暴雪', icon: 'qi-heavy-snow-to-snowstorm'},
        {name: '夜间阵雪', icon: 'qi-shower-snow-night'},
        {name: '夜间飘雪', icon: 'qi-snow-flurry-night'},
        {name: '雪', icon: 'qi-snow'},
        {name: '薄雾', icon: 'qi-mist'},
        {name: '有雾', icon: 'qi-foggy'},
        {name: '阴霾', icon: 'qi-haze'},
        {name: '沙', icon: 'qi-sand'},
        {name: '尘', icon: 'qi-dust'},
        {name: '沙尘暴', icon: 'qi-duststorm'},
        {name: '沙暴', icon: 'qi-sandstorm'},
        {name: '浓雾', icon: 'qi-dense-fog'},
        {name: '强雾', icon: 'qi-strong-fog'},
        {name: '中度霾', icon: 'qi-moderate-haze'},
        {name: '重度霾', icon: 'qi-heavy-haze'},
        {name: '严重霾', icon: 'qi-severe-haze'},
        {name: '浓雾', icon: 'qi-heavy-fog'},
        {name: '特大雾', icon: 'qi-extra-heavy-fog'},
        {name: '新月', icon: 'qi-new-moon'},
        {name: '峨眉月', icon: 'qi-waxing-crescent'},
        {name: '上弦月', icon: 'qi-first-quarter'},
        {name: '盈凸月', icon: 'qi-waxing-gibbous'},
        {name: '满月', icon: 'qi-full-moon'},
        {name: '亏凸月', icon: 'qi-waning-gibbous'},
        {name: '下弦月', icon: 'qi-last-quarter'},
        {name: '残月', icon: 'qi-waning-crescent'},
      ]
    }
  },
  mounted() {
    this.createCheckIcon();
  },

  methods: {
    clickIcon(e){
      if(!this.clicked){
        this.clicked = true
        this.selectedNum = e.target.id
        console.log("validRult=", this.selectedNum,"validRult1=", this.validateIcon, this.selectedNum == this.validateIcon)
        this.validRult = this.selectedNum == this.validateIcon
        console.log("validRult=", this.validRult)
      }
    },
    refresh(){
      this.className = 'restart '
      this.createCheckIcon();
    },
    createCheckIcon(){
      //
      let len = this.qweather_icons.length
      this.iconList = []
      let set = new Set()
      while (true){
        let rand = Math.random() * (len);
        let r = Math.floor(rand)
        set.add(r)
        if(set.size===6) break;
      }
      [...set].map((i)=>{
        var red = Math.floor(Math.random() * 256);
        var green = Math.floor(Math.random() * 256);
        var blue = Math.floor(Math.random() * 256);
        var randomColor = "rgb(" + red + ", " + green + ", " + blue + ")";
        this.iconList.push({name:this.qweather_icons[i].name, icon:this.qweather_icons[i].icon,color:randomColor})
        let rand = Math.random() * 5;
        this.validateIcon = Math.floor(rand)
      })
      this.className = 'ele'
      this.validRult = false
      this.selectedNum =null
      this.clicked = false
    },
    register () {
      this.$alert('暂停开放注册！', '提示', {
        confirmButtonText: '确定',
        // callback: action => {
        //   this.$message({
        //     type: 'info',
        //     message: `action: ${ action }`
        //   });
        // }
      });
    },
    login() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {  // 表单校验合法
          this.request.post("/user/login", this.user).then(res => {
            if (res.code === '200') {
              localStorage.setItem("user", JSON.stringify(res.data))  // 存储用户信息到浏览器
              localStorage.setItem("menus", JSON.stringify(res.data.menus))  // 存储用户信息到浏览器
              // 动态设置当前用户的路由
              setRoutes()
              this.$message.success("登录成功")

              if (res.data.role === 'ROLE_STUDENT') {
                this.$router.push("/front/home")
              } else {
                this.$router.push("/")
              }
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      });
    },

    getIcon(Icon) {
      alert(Icon)
      // this.code = code.toLowerCase()
    },



  }
}
</script>

<style scoped>
body{
  width: 100%;
  height: 100%;
  background: url('bg.jpg') no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;/* 不设置的话页面滑动时，背景会不铺满*/
}
.wrapper1 {
  position: fixed;
  top:0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 10px);
  /*background-image: linear-gradient(to right, orange, indianred);*/
  background: url('bg.jpg') no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;/* 不设置的话页面滑动时，背景会不铺满*/
  overflow: hidden;
  z-index: 9;
  /*opacity: 0.1;*/
}

.iconBox{
  font-size: 24px;
  margin: 10px;
  padding: 5px;
  /*background-color: whitesmoke;*/
  border-radius: 10px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
}
/* 定义一个名为 'fadeIn' 的动画 */

.wrapper {
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: calc(100vh - 10px);
  /*background-image: linear-gradient(to right, orange, indianred);*/
  background: url('bg.jpg') no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;/* 不设置的话页面滑动时，背景会不铺满*/
  overflow: hidden;
  z-index: 0;
}
@keyframes right-move {
  from {transform: translate(10vw);}
  to {
    transform: translate(0);
  }

}
.ele{

animation-name: right-move;


animation-duration: 2s;
  animation-fill-mode: both;

animation-delay: 0.2s;
}
/*.bb{background: black}*/

/*.wrapper,*/
.wrapper::before,
.wrapper::after{
  content: "";
  background: radial-gradient(3px 3px at 150px 50px,#ccffffff,transparent),
              radial-gradient(4px 6px at 250px 450px,#ffffff,transparent),
              radial-gradient(4px 6px at 150px 350px,#ffffff,transparent),
              radial-gradient(2px 5px at 450px 50px,#ffcfff,transparent),
              radial-gradient(4px 5px at 450px 150px,#ffffff,transparent),
              radial-gradient(3px 5px at 850px 350px,#f0f0f0,transparent),
              radial-gradient(5px 9px at 650px 150px,#ffffff,transparent),
              radial-gradient(4px 5px at 450px 550px,#ffffff,transparent),
              radial-gradient(3px 4px at 150px 150px,#ffffff,transparent),
              radial-gradient(2px 5px at 450px 50px,#ffcfff,transparent),
              radial-gradient(4px 5px at 450px 150px,#ffffff,transparent),
              radial-gradient(3px 5px at 850px 350px,#f0f0f0,transparent),
              radial-gradient(5px 4px at 650px 150px,#ffffff,transparent),
              radial-gradient(4px 5px at 450px 550px,#ffffff,transparent),
              radial-gradient(3px 4px at 150px 150px,#ffffff,transparent),
              radial-gradient(2px 7px at 550px 150px,#ffffff,transparent),
              radial-gradient(4px 7px at 750px 150px,#ffffff,transparent),
              radial-gradient(3px 5px at 350px 850px,#f0ffff,transparent),
              radial-gradient(4px 7px at 150px 150px,#ffffff,transparent),
              radial-gradient(3px 5px at 150px 450px,#ffffff,transparent),

              radial-gradient(30px 50px at 150px 650px,#ffffff,transparent);
  background-size: 30vw 50vh;

  position: absolute;
  inset: -100vh 0 0 0;
  animation: animate 5s linear infinite;
}
/*.wrapper::before{*/
/*  margin: -390px;*/
/*  opacity: 0.6;*/
/*  animation-duration: 6s;*/
/*  animation-delay: -1.5s;*/
/*}*/
.wrapper::before{
  margin: -130px;
  opacity: 0.8;
  animation-duration: 10s;
}
@keyframes animate {
  0%{
    transform: translateY(0);
  }
  100%{
    transform: translateY(100vh);
  }

}
</style>
